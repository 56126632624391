@font-face {
  font-family: Afacad;
  src: url('./assets/fonts/Afacad_Flux/static/AfacadFlux-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Afacad;
  src: url('./assets/fonts/Afacad_Flux/static/AfacadFlux-Regular.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Afacad;
  src: url('./assets/fonts/Afacad_Flux/static/AfacadFlux-Light.ttf');
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
  overflow-x: hidden;
}

body {
  font-weight: 500;
  overflow-x: hidden;
  position: relative;
  background-color: #101e1d !important;
  color: #f3f3f3 !important;
  font-family: 'Afacad', sans-serif !important;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
  line-height: normal;
}

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32 ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px, 0px;
  background-color: #0d1616ec;
}

/* logo */
nav.navbar a.navbar-brand{
  scale: 0.7;
  padding-right: 50px;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 500;
  color: #f3f3f3 !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 24px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover{
  opacity: 1;
}
nav.navbar .navbar-nav a.nav-link.active-navbar-link {
  color: #95DBD7 !important;
  opacity: 1;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-weight: 700;
  font-size: 24px;
}

span.navbar-text{
  display: flex;
  align-items: center;
}

.vanta-canvas{
  height: 760px !important;
}


/* Social media icons */
.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a{
  width: 42px;
  height: 42px;
  display: inline-flex;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.social-icon a svg{
  scale: 1.5;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover svg{
  color: #fff;
  transform: scale(1.2);
  transition: transform 0.15s ease-in-out;
}

/* Navbar toggler icon */
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus{
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler {
  border: none;
  padding: 10px;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 18px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #f3f3f3;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus{
  border-bottom: 2px solid #f3f3f3;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before{
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #f3f3f3;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after{
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after{
  transform: rotate(45deg);
  background-color: #f3f3f3;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before{
  transform: translateY(8px) rotate(-45deg);
  background-color: #f3f3f3;
  height: 2px;
}


nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon{
  border-color: transparent;
}

.banner{
  padding-top: 300px;
}

.hello {
  font-size: 28px;
}

.header{
  font-size: 80px;
  font-weight: 700;
  line-height: 0.8;
  margin-bottom: 20px;
}
@media (max-width: 600px) {
  .header {
    font-size: 60px;
    line-height: 1;
    margin-bottom: 10px;
  }
}
.wrap{
  font-weight: 500;
  font-size: 32px;
  display: inline-block;
  line-height: 0.8;
}
.wrap-container{
  padding-bottom: 20px;
  display: inline-block;
  height: 100px;
}

.blinking {
  animation: blink-border 1s ease-in-out infinite;
}

@keyframes blink-border {
  0% {
    color: #f3f3f3;
  }
  60%{
    color: #f3f3f3;
  }
  80% {
   color: transparent;
  }
  100% {
    color: #f3f3f3;
  }
}

.cursor{
  font-weight: 500;
  font-size: 40px;
}

.hireme-container {
  position: relative;
  width: 130px;
  height: 45px;
}

.hireme{
  border-radius: 10px;
  width: 130px;
  height: 45px;
  border: none;
  background-color: #f3f3f3;
  color:#101e1d !important;
  font-size: 24px;
  font-weight: 500;
  z-index: 2;
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.hireme:hover {
  transform: translate(-5px, -5px);
}

.hiremebg{
  transform: translate(5px, 5px);
  border-radius: 10px;
  width: 130px;
  height: 45px;
  border: none;
  background-color: #0D1616 !important;
  font-size: 24px;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.scroll-indicator span {
  color: #f3f3f3;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-left: 45px;
}

.scroll-line {
  border-left: 2px dotted #f3f3f3;
  height: 150px;
  opacity: 0.8;
  margin-left: 60px;
}


#about{
  background-color: #f3f3f3;
  color: #121E1D;
  position: relative;
  padding-bottom: 10px;
}

.pg-title{
  font-weight: 700;
}

#about .paragraph{
  font-weight: 500;
  font-size: 20px;
  text-align: justify;
}

.section-header{
  text-align: left;
  font-size: 80px;
  font-weight: 700;
  line-height: 0.8;
  padding-top: 40px;
  padding-bottom: 30px;
}

@media (max-width: 600px) {
  .section-header {
    font-size: 60px;
  }
}

.skill-item{
  background-color: #f3f3f3;
  border-radius: 11px;
  height: 200px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px
}

.skill-box{
  background-color: #121e1de2;
  margin: 10px 0px 30px 0px;
  text-align: center;
  border-radius: 9px;
  padding: 40px 0;
}


.skill-row{
  padding-top: 50px;
}

.skill-slider{
  width: 85%;
  margin: 0 auto;
  position: relative;
}

.education-work{
  background-image: url('assets/img/bg.svg');
  background-size: cover;
  background-position: center;
}

.school-work{
  width: 600px !important;
  margin-bottom: 15px;
  opacity: 0%;
}

.school-work p{
  margin-bottom: 0;
}

.school-work .dates{
  padding-top: 0;
  font-size: 18px;
}

.school-work .title{
  font-size: 32px;
  font-weight: 700;
  color: #95DBD7;
}

.school-work .subtitle{
  font-size: 24px;
  font-weight: 500;
  color: #f3f3f3;
  margin-bottom: 5px;
}

.school-work .feature{
  font-size: 20px;
  font-weight: 700;
  color: #f3f3f3;
}

.school-work .paragraph{
  font-size: 20px;
  font-weight: 500;
  color: #f3f3f3;
  text-align: justify;
}

.school-work-left{
  text-align: left !important;
}

.school-work-right{
  text-align: right !important;
}

.image-wrapper {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: relative;
  transition: transform 0.2s ease;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .image-wrapper {
    display: none;
  }
}

@media (max-width: 995px) {
  .stroke {
    display: none !important;
  }
}

.flipped{
  transform: rotateX(180deg);
}

.image-wrapper:hover{
  transform: translate(-5px, -5px);
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; 
}

.school-work .logo {
  max-width: 150px;
  margin-bottom: 15px;
  border-radius: 30px;
  opacity: 75%;
  transition: opacity 0.2s ease;
  
}

.school-work .logo:hover{
  opacity: 100%;
}

.fade-in-container {
  animation: fade-in 1s ease-in-out forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

#work{
  padding-bottom: 100px;
}

#projects{
  background-color: #f3f3f3;
  color:#101e1d;
  padding-bottom: 50px;
}

.footer{
  font-size: 26px;
  font-weight: 500;
  padding: 20px 0;
  text-align: center;
}

.footer p {
  margin: 0;
}